import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6acdc684 = () => interopDefault(import('../pages/the-project/index.vue' /* webpackChunkName: "pages/the-project/index" */))
const _cab8c07a = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7be04d49 = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _63ce4354 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6436eb36 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _c62d28a6 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _be7c7458 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _d9b89512 = () => interopDefault(import('../pages/public-information/index.vue' /* webpackChunkName: "pages/public-information/index" */))
const _77873f14 = () => interopDefault(import('../pages/visitor-information/index.vue' /* webpackChunkName: "pages/visitor-information/index" */))
const _05fdd17a = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _6a4f22ec = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _264a0e82 = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _dd1fde40 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _63d5fa1f = () => interopDefault(import('../pages/business-partners/index.vue' /* webpackChunkName: "pages/business-partners/index" */))
const _7675c2c7 = () => interopDefault(import('../pages/programs-in-varosliget/index.vue' /* webpackChunkName: "pages/programs-in-varosliget/index" */))
const _649caf84 = () => interopDefault(import('../pages/the-project/our-first-10-years.vue' /* webpackChunkName: "pages/the-project/our-first-10-years" */))
const _3ea58394 = () => interopDefault(import('../pages/the-project/swiper.vue' /* webpackChunkName: "pages/the-project/swiper" */))
const _1a5bb08d = () => interopDefault(import('../pages/press/media-library/index.vue' /* webpackChunkName: "pages/press/media-library/index" */))
const _c2334ea0 = () => interopDefault(import('../pages/top-locations/balloon-fly.vue' /* webpackChunkName: "pages/top-locations/balloon-fly" */))
const _1ae46222 = () => interopDefault(import('../pages/top-locations/liget-budapest-visitor-center.vue' /* webpackChunkName: "pages/top-locations/liget-budapest-visitor-center" */))
const _461e6e9a = () => interopDefault(import('../pages/top-locations/bird-friendly-learning-trail.vue' /* webpackChunkName: "pages/top-locations/bird-friendly-learning-trail" */))
const _2fb53dba = () => interopDefault(import('../pages/top-locations/house-of-hungarian-music.vue' /* webpackChunkName: "pages/top-locations/house-of-hungarian-music" */))
const _b3352fb4 = () => interopDefault(import('../pages/top-locations/house-of-the-hungarian-millennium.vue' /* webpackChunkName: "pages/top-locations/house-of-the-hungarian-millennium" */))
const _5018cdf2 = () => interopDefault(import('../pages/top-locations/mocsenyi-mihaly-botanical-garden.vue' /* webpackChunkName: "pages/top-locations/mocsenyi-mihaly-botanical-garden" */))
const _a11b4576 = () => interopDefault(import('../pages/top-locations/museum-of-ethnography.vue' /* webpackChunkName: "pages/top-locations/museum-of-ethnography" */))
const _51e8e863 = () => interopDefault(import('../pages/top-locations/museum-of-fine-arts.vue' /* webpackChunkName: "pages/top-locations/museum-of-fine-arts" */))
const _246ca5b2 = () => interopDefault(import('../pages/top-locations/playground-of-varosliget.vue' /* webpackChunkName: "pages/top-locations/playground-of-varosliget" */))
const _4fc7d922 = () => interopDefault(import('../pages/top-locations/varosliget-promenade.vue' /* webpackChunkName: "pages/top-locations/varosliget-promenade" */))
const _28fe0bae = () => interopDefault(import('../pages/press/media-library/_gallerycategory/index.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/index" */))
const _203f2b0c = () => interopDefault(import('../pages/press/media-library/_gallerycategory/_gallery.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/_gallery" */))
const _d3ab30e0 = () => interopDefault(import('../pages/archive/_article.vue' /* webpackChunkName: "pages/archive/_article" */))
const _c20d3ccc = () => interopDefault(import('../pages/business-partners/_partnerInfo.vue' /* webpackChunkName: "pages/business-partners/_partnerInfo" */))
const _6c15e8e3 = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _334d81ae = () => interopDefault(import('../pages/public-information/_data.vue' /* webpackChunkName: "pages/public-information/_data" */))
const _5cdaa356 = () => interopDefault(import('../pages/magazine/_article.vue' /* webpackChunkName: "pages/magazine/_article" */))
const _2f429d40 = () => interopDefault(import('../pages/press/_release.vue' /* webpackChunkName: "pages/press/_release" */))
const _4f849d2f = () => interopDefault(import('../pages/program-series/_programSeries.vue' /* webpackChunkName: "pages/program-series/_programSeries" */))
const _6701b2e9 = () => interopDefault(import('../pages/programs/_program.vue' /* webpackChunkName: "pages/programs/_program" */))
const _726ed518 = () => interopDefault(import('../pages/the-project/_project.vue' /* webpackChunkName: "pages/the-project/_project" */))
const _1868d482 = () => interopDefault(import('../pages/visitor-information/_visitorInformation.vue' /* webpackChunkName: "pages/visitor-information/_visitorInformation" */))
const _673b85e4 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-projekt",
    component: _6acdc684,
    name: "the-project___hu___default"
  }, {
    path: "/adatvedelem",
    component: _cab8c07a,
    name: "privacy-policy___hu___default"
  }, {
    path: "/archivum",
    component: _7be04d49,
    name: "archive___hu___default"
  }, {
    path: "/en",
    component: _63ce4354,
    name: "index___en"
  }, {
    path: "/hirlevel-feliratkozas",
    component: _6436eb36,
    name: "newsletter___hu___default"
  }, {
    path: "/hu",
    component: _63ce4354,
    name: "index___hu"
  }, {
    path: "/karrier",
    component: _c62d28a6,
    name: "career___hu___default"
  }, {
    path: "/kereses",
    component: _be7c7458,
    name: "search___hu___default"
  }, {
    path: "/kozerdeku-adatok",
    component: _d9b89512,
    name: "public-information___hu___default"
  }, {
    path: "/latogatas",
    component: _77873f14,
    name: "visitor-information___hu___default"
  }, {
    path: "/magazin",
    component: _05fdd17a,
    name: "magazine___hu___default"
  }, {
    path: "/sajto",
    component: _6a4f22ec,
    name: "press___hu___default"
  }, {
    path: "/szakmai-anyagok",
    component: _264a0e82,
    name: "documents___hu___default"
  }, {
    path: "/terkep",
    component: _dd1fde40,
    name: "map___hu___default"
  }, {
    path: "/uzleti-partnereinknek",
    component: _63d5fa1f,
    name: "business-partners___hu___default"
  }, {
    path: "/varosligeti-programok",
    component: _7675c2c7,
    name: "programs-in-varosliget___hu___default"
  }, {
    path: "/en/archive",
    component: _7be04d49,
    name: "archive___en"
  }, {
    path: "/en/business-partners",
    component: _63d5fa1f,
    name: "business-partners___en"
  }, {
    path: "/en/career",
    component: _c62d28a6,
    name: "career___en"
  }, {
    path: "/en/hirlevel-feliratkozas",
    component: _6436eb36,
    name: "newsletter___en"
  }, {
    path: "/en/kozerdeku-adatok",
    component: _d9b89512,
    name: "public-information___en"
  }, {
    path: "/en/magazine",
    component: _05fdd17a,
    name: "magazine___en"
  }, {
    path: "/en/map",
    component: _dd1fde40,
    name: "map___en"
  }, {
    path: "/en/press",
    component: _6a4f22ec,
    name: "press___en"
  }, {
    path: "/en/privacy-policy",
    component: _cab8c07a,
    name: "privacy-policy___en"
  }, {
    path: "/en/programs-in-varosliget",
    component: _7675c2c7,
    name: "programs-in-varosliget___en"
  }, {
    path: "/en/search",
    component: _be7c7458,
    name: "search___en"
  }, {
    path: "/en/szakmai-anyagok",
    component: _264a0e82,
    name: "documents___en"
  }, {
    path: "/en/the-project",
    component: _6acdc684,
    name: "the-project___en"
  }, {
    path: "/en/visitor-information",
    component: _77873f14,
    name: "visitor-information___en"
  }, {
    path: "/hu/a-projekt",
    component: _6acdc684,
    name: "the-project___hu"
  }, {
    path: "/hu/adatvedelem",
    component: _cab8c07a,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/archivum",
    component: _7be04d49,
    name: "archive___hu"
  }, {
    path: "/hu/hirlevel-feliratkozas",
    component: _6436eb36,
    name: "newsletter___hu"
  }, {
    path: "/hu/karrier",
    component: _c62d28a6,
    name: "career___hu"
  }, {
    path: "/hu/kereses",
    component: _be7c7458,
    name: "search___hu"
  }, {
    path: "/hu/kozerdeku-adatok",
    component: _d9b89512,
    name: "public-information___hu"
  }, {
    path: "/hu/latogatas",
    component: _77873f14,
    name: "visitor-information___hu"
  }, {
    path: "/hu/magazin",
    component: _05fdd17a,
    name: "magazine___hu"
  }, {
    path: "/hu/sajto",
    component: _6a4f22ec,
    name: "press___hu"
  }, {
    path: "/hu/szakmai-anyagok",
    component: _264a0e82,
    name: "documents___hu"
  }, {
    path: "/hu/terkep",
    component: _dd1fde40,
    name: "map___hu"
  }, {
    path: "/hu/uzleti-partnereinknek",
    component: _63d5fa1f,
    name: "business-partners___hu"
  }, {
    path: "/hu/varosligeti-programok",
    component: _7675c2c7,
    name: "programs-in-varosliget___hu"
  }, {
    path: "/projekt/a-liget-projekt-elso-10-eve",
    component: _649caf84,
    name: "the-project-our-first-10-years___hu___default"
  }, {
    path: "/projekt/swiper",
    component: _3ea58394,
    name: "the-project-swiper___hu___default"
  }, {
    path: "/sajto/mediatar",
    component: _1a5bb08d,
    name: "press-media-library___hu___default"
  }, {
    path: "/top-helyek/ballon-kilato",
    component: _c2334ea0,
    name: "top-locations-balloon-fly___hu___default"
  }, {
    path: "/top-helyek/liget-budapest-latogatokozpont",
    component: _1ae46222,
    name: "top-locations-liget-budapest-visitor-center___hu___default"
  }, {
    path: "/top-helyek/madarbarat-tanosveny",
    component: _461e6e9a,
    name: "top-locations-bird-friendly-learning-trail___hu___default"
  }, {
    path: "/top-helyek/magyar-zene-haza",
    component: _2fb53dba,
    name: "top-locations-house-of-hungarian-music___hu___default"
  }, {
    path: "/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _b3352fb4,
    name: "top-locations-house-of-the-hungarian-millennium___hu___default"
  }, {
    path: "/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _5018cdf2,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu___default"
  }, {
    path: "/top-helyek/neprajzi-muzeum",
    component: _a11b4576,
    name: "top-locations-museum-of-ethnography___hu___default"
  }, {
    path: "/top-helyek/szepmuveszeti-muzeum",
    component: _51e8e863,
    name: "top-locations-museum-of-fine-arts___hu___default"
  }, {
    path: "/top-helyek/varosligeti-nagyjatszoter",
    component: _246ca5b2,
    name: "top-locations-playground-of-varosliget___hu___default"
  }, {
    path: "/top-helyek/varosligeti-promenad",
    component: _4fc7d922,
    name: "top-locations-varosliget-promenade___hu___default"
  }, {
    path: "/en/press/media",
    component: _1a5bb08d,
    name: "press-media-library___en"
  }, {
    path: "/en/the-project/our-first-10-years",
    component: _649caf84,
    name: "the-project-our-first-10-years___en"
  }, {
    path: "/en/the-project/swiper",
    component: _3ea58394,
    name: "the-project-swiper___en"
  }, {
    path: "/en/top-locations/balloon-fly",
    component: _c2334ea0,
    name: "top-locations-balloon-fly___en"
  }, {
    path: "/en/top-locations/bird-friendly-learning-trail",
    component: _461e6e9a,
    name: "top-locations-bird-friendly-learning-trail___en"
  }, {
    path: "/en/top-locations/house-of-hungarian-music",
    component: _2fb53dba,
    name: "top-locations-house-of-hungarian-music___en"
  }, {
    path: "/en/top-locations/house-of-the-hungarian-millennium",
    component: _b3352fb4,
    name: "top-locations-house-of-the-hungarian-millennium___en"
  }, {
    path: "/en/top-locations/liget-budapest-visitor-center",
    component: _1ae46222,
    name: "top-locations-liget-budapest-visitor-center___en"
  }, {
    path: "/en/top-locations/mocsenyi-mihaly-botanical-garden",
    component: _5018cdf2,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___en"
  }, {
    path: "/en/top-locations/museum-of-ethnography",
    component: _a11b4576,
    name: "top-locations-museum-of-ethnography___en"
  }, {
    path: "/en/top-locations/museum-of-fine-arts",
    component: _51e8e863,
    name: "top-locations-museum-of-fine-arts___en"
  }, {
    path: "/en/top-locations/playground-of-varosliget",
    component: _246ca5b2,
    name: "top-locations-playground-of-varosliget___en"
  }, {
    path: "/en/top-locations/varosliget-promenade",
    component: _4fc7d922,
    name: "top-locations-varosliget-promenade___en"
  }, {
    path: "/hu/projekt/a-liget-projekt-elso-10-eve",
    component: _649caf84,
    name: "the-project-our-first-10-years___hu"
  }, {
    path: "/hu/projekt/swiper",
    component: _3ea58394,
    name: "the-project-swiper___hu"
  }, {
    path: "/hu/sajto/mediatar",
    component: _1a5bb08d,
    name: "press-media-library___hu"
  }, {
    path: "/hu/top-helyek/ballon-kilato",
    component: _c2334ea0,
    name: "top-locations-balloon-fly___hu"
  }, {
    path: "/hu/top-helyek/liget-budapest-latogatokozpont",
    component: _1ae46222,
    name: "top-locations-liget-budapest-visitor-center___hu"
  }, {
    path: "/hu/top-helyek/madarbarat-tanosveny",
    component: _461e6e9a,
    name: "top-locations-bird-friendly-learning-trail___hu"
  }, {
    path: "/hu/top-helyek/magyar-zene-haza",
    component: _2fb53dba,
    name: "top-locations-house-of-hungarian-music___hu"
  }, {
    path: "/hu/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _b3352fb4,
    name: "top-locations-house-of-the-hungarian-millennium___hu"
  }, {
    path: "/hu/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _5018cdf2,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu"
  }, {
    path: "/hu/top-helyek/neprajzi-muzeum",
    component: _a11b4576,
    name: "top-locations-museum-of-ethnography___hu"
  }, {
    path: "/hu/top-helyek/szepmuveszeti-muzeum",
    component: _51e8e863,
    name: "top-locations-museum-of-fine-arts___hu"
  }, {
    path: "/hu/top-helyek/varosligeti-nagyjatszoter",
    component: _246ca5b2,
    name: "top-locations-playground-of-varosliget___hu"
  }, {
    path: "/hu/top-helyek/varosligeti-promenad",
    component: _4fc7d922,
    name: "top-locations-varosliget-promenade___hu"
  }, {
    path: "/en/press/media/:gallerycategory",
    component: _28fe0bae,
    name: "press-media-library-gallerycategory___en"
  }, {
    path: "/hu/sajto/mediatar/:gallerycategory",
    component: _28fe0bae,
    name: "press-media-library-gallerycategory___hu"
  }, {
    path: "/en/press/media/:gallerycategory/:gallery",
    component: _203f2b0c,
    name: "press-media-library-gallerycategory-gallery___en"
  }, {
    path: "/hu/sajto/mediatar/:gallerycategory/:gallery",
    component: _203f2b0c,
    name: "press-media-library-gallerycategory-gallery___hu"
  }, {
    path: "/en/archive/:article",
    component: _d3ab30e0,
    name: "archive-article___en"
  }, {
    path: "/en/business-partners/:partnerInfo",
    component: _c20d3ccc,
    name: "business-partners-partnerInfo___en"
  }, {
    path: "/en/career/:position",
    component: _6c15e8e3,
    name: "career-position___en"
  }, {
    path: "/en/kozerdeku-adatok/:data",
    component: _334d81ae,
    name: "public-information-data___en"
  }, {
    path: "/en/magazine/:article",
    component: _5cdaa356,
    name: "magazine-article___en"
  }, {
    path: "/en/press/:release",
    component: _2f429d40,
    name: "press-release___en"
  }, {
    path: "/en/program-series/:programSeries",
    component: _4f849d2f,
    name: "program-series-programSeries___en"
  }, {
    path: "/en/programs/:program",
    component: _6701b2e9,
    name: "programs-program___en"
  }, {
    path: "/en/the-project/:project",
    component: _726ed518,
    name: "the-project-project___en"
  }, {
    path: "/en/visitor-information/:visitorInformation",
    component: _1868d482,
    name: "visitor-information-visitorInformation___en"
  }, {
    path: "/hu/archivum/:article",
    component: _d3ab30e0,
    name: "archive-article___hu"
  }, {
    path: "/hu/karrier/:position",
    component: _6c15e8e3,
    name: "career-position___hu"
  }, {
    path: "/hu/kozerdeku-adatok/:data",
    component: _334d81ae,
    name: "public-information-data___hu"
  }, {
    path: "/hu/latogatas/:visitorInformation",
    component: _1868d482,
    name: "visitor-information-visitorInformation___hu"
  }, {
    path: "/hu/magazin/:article",
    component: _5cdaa356,
    name: "magazine-article___hu"
  }, {
    path: "/hu/programok/:program",
    component: _6701b2e9,
    name: "programs-program___hu"
  }, {
    path: "/hu/programsorozatok/:programSeries",
    component: _4f849d2f,
    name: "program-series-programSeries___hu"
  }, {
    path: "/hu/projekt/:project",
    component: _726ed518,
    name: "the-project-project___hu"
  }, {
    path: "/hu/sajto/:release",
    component: _2f429d40,
    name: "press-release___hu"
  }, {
    path: "/hu/uzleti-partnereinknek/:partnerInfo",
    component: _c20d3ccc,
    name: "business-partners-partnerInfo___hu"
  }, {
    path: "/sajto/mediatar/:gallerycategory",
    component: _28fe0bae,
    name: "press-media-library-gallerycategory___hu___default"
  }, {
    path: "/sajto/mediatar/:gallerycategory/:gallery",
    component: _203f2b0c,
    name: "press-media-library-gallerycategory-gallery___hu___default"
  }, {
    path: "/archivum/:article",
    component: _d3ab30e0,
    name: "archive-article___hu___default"
  }, {
    path: "/en/:slug",
    component: _673b85e4,
    name: "slug___en"
  }, {
    path: "/hu/:slug",
    component: _673b85e4,
    name: "slug___hu"
  }, {
    path: "/karrier/:position",
    component: _6c15e8e3,
    name: "career-position___hu___default"
  }, {
    path: "/kozerdeku-adatok/:data",
    component: _334d81ae,
    name: "public-information-data___hu___default"
  }, {
    path: "/latogatas/:visitorInformation",
    component: _1868d482,
    name: "visitor-information-visitorInformation___hu___default"
  }, {
    path: "/magazin/:article",
    component: _5cdaa356,
    name: "magazine-article___hu___default"
  }, {
    path: "/programok/:program",
    component: _6701b2e9,
    name: "programs-program___hu___default"
  }, {
    path: "/programsorozatok/:programSeries",
    component: _4f849d2f,
    name: "program-series-programSeries___hu___default"
  }, {
    path: "/projekt/:project",
    component: _726ed518,
    name: "the-project-project___hu___default"
  }, {
    path: "/sajto/:release",
    component: _2f429d40,
    name: "press-release___hu___default"
  }, {
    path: "/uzleti-partnereinknek/:partnerInfo",
    component: _c20d3ccc,
    name: "business-partners-partnerInfo___hu___default"
  }, {
    path: "/",
    component: _63ce4354,
    name: "index___hu___default"
  }, {
    path: "/:slug",
    component: _673b85e4,
    name: "slug___hu___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
